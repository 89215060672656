import { createSlice } from '@reduxjs/toolkit';
import { ArchiveOrderAction, GetOrdersAction } from '../actions/OrderAction';
export const ordersReducer = createSlice({
    name: "Order Reducer",
    initialState: {
        loading: false,
        archiveLoad: false,
        totalItems: "",
        currentPage: "",
        orders: [],
    },
    reducers: {
        resetOrders(state) {
            state.orders = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetOrdersAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetOrdersAction.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.orders = [
                    ...state.orders,
                    ...payload.data.data.filter((newOrder) => {
                        return !state.orders.some((order) => order.id === newOrder.id);
                    })
                ];
                state.totalItems = payload.data.totalItems;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetOrdersAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(ArchiveOrderAction.pending, (state) => {
                state.archiveLoad = true;
            })
            .addCase(ArchiveOrderAction.fulfilled, (state, res,) => {
                state.archiveLoad = false;
                state.orders = state.orders.filter(newOrder => newOrder.id !== res.meta.arg);
                state.totalItems = state.totalItems - 1
            })
            .addCase(ArchiveOrderAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.archiveLoad = false;
            })
    }
});

export const { resetOrders } = ordersReducer.actions;
export default ordersReducer.reducer;
