import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import {
  GetAboutUsAction,
  UpdateAboutUsAction,
} from "../../../redux/actions/AboutUsAction";
import { toast } from "react-toastify";
import { FileUpload } from "primereact/fileupload";
import { imageURL } from "../../../redux/Api";

const UpdateAboutUs = ({ updateModal }) => {
  const dispatch = useDispatch();
  const { loading, aboutUs } = useSelector((state) => state.about);
  const [aboutInfo, setAboutInfo] = useState({});

  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("facebook", aboutInfo.facebook ?? aboutUs.facebook);
    formData.append("instagram", aboutInfo.instagram ?? aboutUs.instagram);
    formData.append("bio", aboutInfo.bio ?? aboutUs.bio);
    formData.append("email", aboutInfo.email ?? aboutUs.email);
    formData.append("word_one", aboutInfo.word_one ?? aboutUs.word_one);
    formData.append("word_two", aboutInfo.word_two ?? aboutUs.word_two);
    formData.append("word_three", aboutInfo.word_three ?? aboutUs.word_three);
    formData.append("word_four", aboutInfo.word_four ?? aboutUs.word_four);
    formData.append("word_five", aboutInfo.word_five ?? aboutUs.word_five);
    formData.append(
      "phone_mobile",
      aboutInfo.phone_mobile ?? aboutUs.phone_mobile
    );
    // console.log(aboutInfo.image);
    if (aboutInfo.image !== undefined)
      formData.append("image", aboutInfo.image);

    dispatch(UpdateAboutUsAction(formData))
      .then(({ payload }) => {
        // console.log(payload);
        if (payload?.data) {
          toast.success(payload.message);
          updateModal(false);
          dispatch(GetAboutUsAction());
        } else {
          toast.error(payload.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className="input-wrapper file-upload">
          <h4 className="">Profile Image</h4>
          <FileUpload
            mode="basic"
            chooseLabel="Choose an image"
            cancelLabel="Cancel"
            accept="image/*"
            onClear={(event) => setAboutInfo({ ...aboutInfo, image: null })}
            onSelect={(event) =>
              setAboutInfo({ ...aboutInfo, image: event.files[0] })
            }
          />
          <div className="image-preview">
            {aboutInfo.image ? (
              <img src={aboutInfo.image.objectURL} alt="project-preview" />
            ) : (
              <img src={imageURL + aboutUs.image_path} alt="project-preview" />
            )}
          </div>
        </div>

        <div className="input-wrapper">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-instagram"></i>
            </span>
            <InputText
              required
              defaultValue={aboutUs.instagram}
              type="url"
              placeholder="Instagram Link"
              onChange={(e) =>
                setAboutInfo({ ...aboutInfo, instagram: e.target.value })
              }
            />
          </div>
        </div>

        <div className="input-wrapper">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-facebook"></i>
            </span>
            <InputText
              required
              defaultValue={aboutUs.facebook}
              type="url"
              placeholder="Facebook Link"
              onChange={(e) =>
                setAboutInfo({ ...aboutInfo, facebook: e.target.value })
              }
            />
          </div>
        </div>

        <div className="input-wrapper">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-envelope"></i>
            </span>
            <InputText
              required
              defaultValue={aboutUs.email}
              type="email"
              placeholder="Mail"
              onChange={(e) =>
                setAboutInfo({ ...aboutInfo, email: e.target.value })
              }
            />
          </div>
        </div>

        <div className="input-wrapper">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-phone"></i>
            </span>
            <InputText
              required
              defaultValue={aboutUs.phone_mobile}
              type="tel"
              placeholder="Mobile Phone"
              onChange={(e) =>
                setAboutInfo({ ...aboutInfo, phone_mobile: e.target.value })
              }
            />
          </div>
        </div>

        <div className="sections">
          <h4 className="">Bio</h4>
          <div className="input-wrapper">
            <InputTextarea
              minLength={50}
              required
              rows={5}
              defaultValue={aboutUs.bio}
              className="custom-input ar"
              autoResize
              placeholder="نبذة تعريفية"
              onChange={(e) =>
                setAboutInfo({
                  ...aboutInfo,
                  bio: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="sections">
          <h4 className="">Key Words</h4>
          <div className="input-wrapper flex">
            <InputText
              required
              defaultValue={aboutUs.word_one}
              type="text"
              placeholder="Key Word"
              onChange={(e) =>
                setAboutInfo({
                  ...aboutInfo,
                  word_one: e.target.value,
                })
              }
            />
            <InputText
              required
              type="text"
              defaultValue={aboutUs.word_two}
              placeholder="Key Word"
              onChange={(e) =>
                setAboutInfo({
                  ...aboutInfo,
                  word_two: e.target.value,
                })
              }
            />
            <InputText
              required
              type="text"
              defaultValue={aboutUs.word_three}
              placeholder="Key Word"
              onChange={(e) =>
                setAboutInfo({
                  ...aboutInfo,
                  word_three: e.target.value,
                })
              }
            />
            <InputText
              required
              type="text"
              defaultValue={aboutUs.word_four}
              placeholder="Key Word"
              onChange={(e) =>
                setAboutInfo({
                  ...aboutInfo,
                  word_four: e.target.value,
                })
              }
            />
            <InputText
              required
              type="text"
              defaultValue={aboutUs.word_five}
              placeholder="Key Word"
              onChange={(e) =>
                setAboutInfo({
                  ...aboutInfo,
                  word_five: e.target.value,
                })
              }
            />
          </div>
        </div>

        <span className="actions">
          <Button
            size="small"
            className="dark"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => updateModal(false)}
          />
          <Button
            size="small"
            className="pink"
            label="Update"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </>
  );
};

export default UpdateAboutUs;
