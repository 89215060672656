import { configureStore } from '@reduxjs/toolkit';
import AboutUsReducer from './reducers/AboutUsReducer';
import AuthReducer from './reducers/AuthReducer';
import ProductReducer from './reducers/ProductReducer';
import OrderReducer from './reducers/OrderReducer';
import SettingReducer from './reducers/SettingReducer';


const store = configureStore({
    reducer: {
        auth: AuthReducer,
        products: ProductReducer,
        about: AboutUsReducer,
        orders: OrderReducer,
        setting: SettingReducer,
    }
});
export default store