export const baseURL = 'https://cosmetics.it.mr-appss.com/api/';
export const imageURL = 'https://cosmetics.it.mr-appss.com/';

//Login
export const loginAPI = `${baseURL}auth/login`

//Products
export const GetProductsAPI = `${baseURL}admin/product/get?page=`
export const GetProductsByTypeAPI = `${baseURL}admin/product/get/`
export const AddProductAPI = `${baseURL}admin/product/add`
export const RemoveProductAPI = `${baseURL}admin/product/delete/`
export const UpdateProductAPI = `${baseURL}admin/product/update/`

//About
export const GetAboutUsAPI = `${baseURL}admin/about/get`
export const UpdateAboutUsAPI = `${baseURL}admin/about/update`

//Orders
export const GetOrdersAPI = `${baseURL}admin/order/get?page=`
export const ArchiveOrderAPI = `${baseURL}admin/order/archive/`

//Setting
export const ChangePasswordAPI = `${baseURL}auth/password/change`
