import React from "react";
import { Button } from "primereact/button";
import { HiOutlineMail } from "react-icons/hi";
import { GoNumber } from "react-icons/go";
import { MdTitle } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
import { MdPriceCheck } from "react-icons/md";
import { motion } from "framer-motion";
import { imageURL } from "../../redux/Api";
import "./OrderCard.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  ArchiveOrderAction,
  GetOrdersAction,
} from "../../redux/actions/OrderAction";
import { toast } from "react-toastify";
import { paginatorSize } from "../../redux/constant";

const OrderCard = ({ order, index, checked }) => {
  const { currentPage, archiveLoad } = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  const typeHandler = (type) => {
    switch (Number(type)) {
      case 1:
        return "Hair Care";
      case 2:
        return "Skin Care";
      case 3:
        return "Nail, Lash, and Brow Care";
      case 4:
        return "Therapeutic Courses";
      case 5:
        return "Offers";
      default:
        return "All Categories";
    }
  };

  const archiveHandler = () => {
    let info = {
      page: currentPage,
      size: paginatorSize,
      isPaginate: 1,
      status: checked ? 1 : 0,
    };
    dispatch(ArchiveOrderAction(order.id))
      .then(({ payload }) => {
        if (payload?.status) {
          toast.success(payload.message);
          dispatch(GetOrdersAction(info));
        } else {
          toast.error(payload.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <motion.div
      className="order-card"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.5 }}
    >
      <div className="order-details">
        <div className="left-section">
          <div className="product-details">
            <span>
              <MdTitle />
              <p>اسم المنتج: {order.product.title}</p>
            </span>
            <span>
              <BiCategory />
              <p>صنف المنتج: {typeHandler(order.product.type)}</p>
            </span>
            <span>
              <MdPriceCheck />
              <p>سعر المنتج: {order.product.price}</p>
            </span>
          </div>
          <hr className="divider" />
          <div className="user-details">
            <span>
              <HiOutlineMail />
              <p>البريد: {order.email}</p>
            </span>
            <span>
              <GoNumber />
              <p>الكمية: {order.quantity}</p>
            </span>
            <strong>الوصف</strong>
            <p className="desc">{order.description}</p>
          </div>
        </div>
        <div className="product-image right-section">
          <img
            src={
              order.product.image_path
                ? imageURL + order.product.image_path
                : require("../../assets/images/default.png")
            }
            alt="product-order"
            loading="lazy"
          />
        </div>
      </div>
      {!checked && (
        <div className="button-container">
          <Button
            size="small"
            className="pink"
            label="Mark As Archived"
            severity="secondary"
            onClick={archiveHandler}
            loading={archiveLoad}
          />
        </div>
      )}
    </motion.div>
  );
};

export default OrderCard;
