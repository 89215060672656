import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { imageURL } from "../../../redux/Api";
import { toast } from "react-toastify";
import {
  GetProductsByTypeAction,
  UpdateProductAction,
} from "../../../redux/actions/ProductAction";

const UpdateDialog = ({ rowData, basicRows, updateModal, type }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.products);
  // console.log(rowData);
  const [productInfo, setProductInfo] = useState([
    {
      description: null,
      id: 7,
      image_path: null,
      price: 0,
      title: "Skin Care Product1",
      type: 2,
    },
  ]);
  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    let info = {
      size: basicRows,
      page: currentPage,
      isPaginate: 1,
      type: type,
    };
    if (productInfo.price?.length === 0) formData.append("price", 0);
    else {
      formData.append("price", productInfo.price ?? rowData.price);
    }
    formData.append("title", productInfo.title ?? rowData.title);
    formData.append("type", productInfo.type ?? rowData.type);
    formData.append(
      "description",
      productInfo.description ?? rowData.description
    );

    if (productInfo.image !== undefined)
      formData.append("image", productInfo.image);

    let data = { id: rowData.id, obj: formData };

    dispatch(UpdateProductAction(data))
      .then(({ payload }) => {
        // console.log(payload);
        if (payload?.data) {
          toast.success(payload.message);
          updateModal(false);
          dispatch(GetProductsByTypeAction(info));
        } else {
          toast.error(payload.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="container">
      <form onSubmit={submitHandler}>
        <div className="input-wrapper file-upload">
          <div className="image-preview">
            {productInfo.image ? (
              <img src={productInfo.image.objectURL} alt="product-preview" />
            ) : rowData.image_path === null ? (
              <img
                src={require("../../../assets/images/default.png")}
                alt="product-preview"
              />
            ) : (
              <img src={imageURL + rowData.image_path} alt="product-preview" />
            )}
          </div>
          <h4 className="">Product Image</h4>
          <FileUpload
            mode="basic"
            chooseLabel="Choose an image"
            cancelLabel="Cancel"
            accept="image/*"
            onClear={() => setProductInfo({ ...productInfo, image: null })}
            onSelect={(event) =>
              setProductInfo({ ...productInfo, image: event.files[0] })
            }
          />
        </div>

        <div className="input-wrapper">
          <InputText
            defaultValue={rowData.title}
            className="custom-input"
            type="text"
            placeholder="Product Title"
            onChange={(e) =>
              setProductInfo({ ...productInfo, title: e.target.value })
            }
          />
        </div>

        <div className="input-wrapper">
          <InputText
            defaultValue={rowData.price}
            className="custom-input"
            type="text"
            placeholder="Product Price"
            onChange={(e) =>
              setProductInfo({
                ...productInfo,
                price: e.target.value,
              })
            }
          />
        </div>

        <div className="input-wrapper">
          <InputTextarea
            defaultValue={rowData.description}
            rows={5}
            className="custom-input"
            id="address"
            autoResize
            placeholder="وصف المنتج"
            onChange={(e) =>
              setProductInfo({
                ...productInfo,
                description: e.target.value,
              })
            }
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="dark"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => updateModal(false)}
            type="submit"
          />
          <Button
            size="small"
            className="pink"
            label="Update"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default UpdateDialog;
