import React from "react";
import LoadingFS from "../../components/loading/LoadingFS";
import ProductsDT from "../../components/datatable/ProductsDT";
import { useSelector } from "react-redux";

const AllCategories = ({ toast }) => {
  const { loading } = useSelector((state) => state.products);

  return (
    <div>
      {loading && <LoadingFS />}
      <ProductsDT isGet={true} />
    </div>
  );
};

export default AllCategories;
