import React, { useEffect, useState } from "react";
import "./Orders.scss";
import OrderCard from "../../components/order-card/OrderCard";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch, useSelector } from "react-redux";
import { GetOrdersAction } from "../../redux/actions/OrderAction";
import { paginatorSize } from "../../redux/constant";
import EmptyOrders from "../../components/empty-orders/EmptyOrders";
import LoadingFS from "../../components/loading/LoadingFS";
import { Button } from "primereact/button";
import { resetOrders } from "../../redux/reducers/OrderReducer";

const Orders = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const { orders, currentPage, loading, totalItems } = useSelector(
    (state) => state.orders
  );

  const [page, setPage] = useState(1);

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    let info = {
      size: paginatorSize,
      page: page,
      isPaginate: 1,
      status: checked ? 1 : 0,
    };
    dispatch(GetOrdersAction(info));
  }, [dispatch, page, checked]);

  useEffect(() => {
    let info = {
      page: currentPage,
      size: paginatorSize,
      isPaginate: 1,
      status: checked ? 1 : 0,
    };
    dispatch(GetOrdersAction(info));
  }, [dispatch, currentPage, checked]);

  return (
    <div className="orders-container">
      {loading && <LoadingFS />}
      <div className="check-btn">
        <h5>In Progress</h5>
        <InputSwitch
          checked={checked}
          onChange={(e) => {
            setChecked(e.value);
            setPage(1);
            dispatch(resetOrders());
          }}
        />
        <h5>Archived</h5>
      </div>
      {orders?.map((order, index) => (
        <OrderCard key={index} order={order} index={index} checked={checked} />
      ))}
      {orders.length === 0 && <EmptyOrders />}
      {totalItems !== orders.length && (
        <div className="load-more">
          <Button
            size="small"
            className="pink"
            loading={loading}
            type="submit"
            onClick={handleLoadMore}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default Orders;
