import React from "react";
import "./EmptyOrders.scss";

const EmptyOrders = () => {
  return (
    <div className="empty-orders">
      <span>No Orders Found !!</span>
    </div>
  );
};

export default EmptyOrders;
