import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import NotFound from "./components/notfound/NotFound";
import AboutUs from "./pages/about-us/AboutUs";
import Login from "./pages/login/Login";
import AllCategories from "./pages/all-categories/AllCategories";
import HairCare from "./pages/hair-care/HairCare";
import SkinCare from "./pages/skin-care/SkinCare";
import NLBC from "./pages/nail-lash-brow-care/NLBC";
import Courses from "./pages/therapeutic-courses/Courses";
import Offers from "./pages/offers/Offers";
import Orders from "./pages/orders/Orders";
import ChangePassword from "./pages/change-password/ChangePassword";

const AppRoutes = () => {
    const isLoginPage = window.location.pathname === '/';
    return (
        <Router>
            {!isLoginPage && <Sidebar />}
            <div className="content-container">
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/all-categories" element={<AllCategories />} />
                    <Route path="/hair-care" element={<HairCare />} />
                    <Route path="/skin-care" element={<SkinCare />} />
                    <Route path="/nail-lash-brow-care" element={<NLBC />} />
                    <Route path="/therapeutic-courses" element={<Courses />} />
                    <Route path="/offers" element={<Offers />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                </Routes>
            </div>
        </Router>
    );
};

export default AppRoutes;
