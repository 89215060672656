import React from "react";
import { motion } from "framer-motion";
import "./AboutUsCard.scss";

const AboutUsCard = ({ image, title, text, link, index }) => {
  return (
    <motion.div
      className="about-us-card"
      initial={{ opacity: 0, y: 50 }} // Initial animation values
      animate={{ opacity: 1, y: 0 }} // Animation when component is mounted
      transition={{ duration: 0.5, delay: index * 0.2 }} // Animation duration and delay
    >
      <img src={image} alt={title} className="card-image" />
      <div className="card-header">
        <h3 className="card-title">{title}</h3>
        <div>
          {link ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={link}
              className="card-link"
            >
              {link}
            </a>
          ) : (
            <span>{text}</span>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default AboutUsCard;
