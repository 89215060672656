import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import { toast } from "react-toastify";
import {
  AddProductAction,
  GetProductsByTypeAction,
} from "../../../redux/actions/ProductAction";

const AddDialog = ({ basicRows, createModal, type }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.products);
  const [productInfo, setProductInfo] = useState([]);
  const submitHandler = (e) => {
    e.preventDefault();
    // console.log(productInfo);
    let formData = new FormData();
    // console.log(productInfo);
    formData.append("title", productInfo.title);
    if (productInfo.price) formData.append("price", productInfo.price);
    if (productInfo.description)
      formData.append("description", productInfo.description);
    formData.append("type", type);
    formData.append("image", productInfo.image);

    let info = {
      size: basicRows,
      page: currentPage,
      isPaginate: 1,
      type: type,
    };

    dispatch(AddProductAction(formData))
      .then(({ payload }) => {
        // console.log(payload);
        if (payload?.data) {
          toast.success(payload.message);
          createModal(false);
          dispatch(GetProductsByTypeAction(info));
        } else {
          toast.error(payload.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      <form onSubmit={submitHandler}>
        <div className="input-wrapper file-upload">
          <h4 className="">Product Image</h4>
          <FileUpload
            mode="basic"
            chooseLabel="Choose an image"
            cancelLabel="Cancel"
            accept="image/*"
            onClear={() => setProductInfo({ ...productInfo, image: null })}
            onSelect={(event) =>
              setProductInfo({ ...productInfo, image: event.files[0] })
            }
          />
          <div className="image-preview">
            {productInfo.image && (
              <img src={productInfo.image.objectURL} alt="project-preview" />
            )}
          </div>
        </div>

        <div className="input-wrapper">
          <InputText
            required
            className="custom-input"
            type="text"
            placeholder="Product Title"
            onChange={(e) =>
              setProductInfo({ ...productInfo, title: e.target.value })
            }
          />
        </div>

        <div className="input-wrapper">
          <InputText
            className="custom-input"
            type="text"
            placeholder="Product Price"
            onChange={(e) =>
              setProductInfo({ ...productInfo, price: e.target.value })
            }
          />
        </div>

        <div className="input-wrapper">
          <InputTextarea
            rows={5}
            className="custom-input"
            id="address"
            autoResize
            placeholder="وصف عن المنتج"
            onChange={(e) =>
              setProductInfo({
                ...productInfo,
                description: e.target.value,
              })
            }
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="dark"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
          />
          <Button
            size="small"
            className="pink"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </>
  );
};

export default AddDialog;
