import React from "react";
import LoginForm from "./LoginForm";
import { motion } from "framer-motion";
import "./styles/Login.scss";

const Login = () => {
  return (
    <div className="login">
      <motion.div
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", stiffness: 200 }}
      >
        <LoginForm />
      </motion.div>
    </div>
  );
};

export default Login;
