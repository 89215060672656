import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import AboutUsCard from "../../components/about-us-card/AboutUsCard";
import { useDispatch, useSelector } from "react-redux";
import { GetAboutUsAction } from "../../redux/actions/AboutUsAction";
import { Dialog } from "primereact/dialog";
import UpdateAboutUs from "./actions/UpdateAboutUs";
import LoadingFS from "../../components/loading/LoadingFS";
import "./styles/AboutUs.scss";
import { imageURL } from "../../redux/Api";

const AboutUs = () => {
  const dispatch = useDispatch();
  const { loading, aboutUs } = useSelector((state) => state.about);
  const [updateModal, setUpdateModal] = useState(false);

  useEffect(() => {
    dispatch(GetAboutUsAction());
  }, [dispatch]);

  const data = [
    {
      title: "Facebook",
      link: aboutUs?.facebook,
      image: require("../../assets/images/social-icons/facebook.png"),
    },
    {
      title: "Mail",
      link: aboutUs?.email,
      image: require("../../assets/images/social-icons/mail.png"),
    },
    {
      title: "Instagram",
      link: aboutUs?.instagram,
      image: require("../../assets/images/social-icons/instagram.png"),
    },
    {
      title: "Phone Number",
      text: aboutUs?.phone_mobile,
      image: require("../../assets/images/social-icons/phone.png"),
    },
  ];

  return (
    <div className="about-us cs-container">
      {loading && <LoadingFS />}
      <div className="header">
        <h2 className="title">About Me</h2>

        <Button
          size="small"
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-success"
          aria-label="Submit"
          onClick={() => setUpdateModal(true)}
        />
      </div>

      <div className="cover-section ">
        <div className="cover-image">
          <img src={imageURL + aboutUs.image_path} alt="" />
        </div>
        <div className="bio">
          {aboutUs.length !== 0 && (
            <div className="items">
              <div className="item">{aboutUs.word_one}</div>
              <div className="item">{aboutUs.word_two}</div>
              <div className="item">{aboutUs.word_three}</div>
              <div className="item">{aboutUs.word_four}</div>
              <div className="item">{aboutUs.word_five}</div>
            </div>
          )}
          <p className="arabic-text ">{aboutUs.bio}</p>
        </div>
      </div>

      <div className="grid">
        {data.map((item, index) => {
          return (
            <AboutUsCard
              key={index}
              index={index}
              title={item.title}
              link={item.link}
              text={item.text}
              image={item.image}
            />
          );
        })}
      </div>

      <Dialog
        header="About Me Update"
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
      >
        <UpdateAboutUs updateModal={(e) => setUpdateModal(e)} />
      </Dialog>
    </div>
  );
};

export default AboutUs;
