import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddProductAPI, GetProductsAPI, GetProductsByTypeAPI, RemoveProductAPI, UpdateProductAPI } from "../Api";
import axiosInstance, { errorMessage } from "../constant";

export const GetProductsAction = createAsyncThunk("admin/products/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetProductsAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetProductsByTypeAction = createAsyncThunk("admin/products/type/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetProductsByTypeAPI + info.type + "?page=" + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddProductAction = createAsyncThunk("admin/products/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddProductAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateProductAction = createAsyncThunk("admin/products/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.put(UpdateProductAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const RemoveProductAction = createAsyncThunk("admin/products/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(RemoveProductAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});