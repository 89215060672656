import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { paginatorSize } from "../../redux/constant";
import { Image } from "primereact/image";
import { imageURL } from "../../redux/Api";
import {
  GetProductsAction,
  GetProductsByTypeAction,
} from "../../redux/actions/ProductAction";
import "../../assets/utils/datatable.scss";

const ProductsDT = ({
  categoryId,
  createModal,
  deleteModal,
  updateModal,
  isGet,
  type,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);
  const { totalItems, products } = useSelector((state) => state.products);
  const [dtName, setDtName] = useState("");

  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  useEffect(() => {
    let info = {
      page: currentPage,
      size: paginatorSize,
      isPaginate: 1,
      type: type,
    };
    switch (type) {
      case 1:
        setDtName("Hair Care");
        break;
      case 2:
        setDtName("Skin Care");
        break;
      case 3:
        setDtName("Nail, Lash, and Brow Care");
        break;
      case 4:
        setDtName("Therapeutic Courses");
        break;
      case 5:
        setDtName("Offers");
        break;
      default:
        setDtName("All Categories");
        break;
    }
    isGet
      ? dispatch(GetProductsAction(info))
      : dispatch(GetProductsByTypeAction(info));
  }, [dispatch, currentPage, categoryId, isGet, type]);

  const header = (
    <div className="header">
      <span className="title">{dtName}</span>
      {!isGet && (
        <Button
          size="small"
          icon="pi pi-plus"
          onClick={() => {
            createModal(true, undefined, basicRows);
          }}
          rounded
          text
          raised
          aria-label="Favorite"
        />
      )}
    </div>
  );
  const acitonBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          size="small"
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          aria-label="Submit"
          onClick={() => {
            deleteModal(true, rowData, basicRows);
          }}
        />
        <Button
          size="small"
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-primary"
          aria-label="Submit"
          onClick={() => {
            updateModal(true, rowData, basicRows);
          }}
        />
      </>
    );
  };
  const imgHandler = (image) => {
    return (
      <Image
        className="image"
        src={
          image ? imageURL + image : require("../../assets/images/default.png")
        }
        alt="offer_img"
        loading="lazy"
        preview={image}
        downloadable
      />
    );
  };
  return (
    <div className={`datatable`}>
      <DataTable stripedRows header={header} value={products}>
        <Column field="title" header="Name"></Column>
        <Column
          body={(e) => {
            return e.description.length === 0 ? ".." : e.description;
          }}
          header="Description"
        ></Column>
        <Column
          body={(e) => {
            return e.price === 0 ? ".." : e.price;
          }}
          header="Price"
        ></Column>
        <Column
          body={(rowData) => imgHandler(rowData.image_path)}
          header="Image"
        ></Column>
        {!isGet && (
          <Column
            align={"center"}
            body={acitonBodyTemplate}
            header="Actions"
          ></Column>
        )}
      </DataTable>
      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={totalItems}
        onPageChange={onBasicPageChange}
      ></Paginator>
    </div>
  );
};

export default ProductsDT;
