import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GrServices } from "react-icons/gr";
import { BiCategory } from "react-icons/bi";
import { BiSolidOffer } from "react-icons/bi";
import { SiAboutdotme } from "react-icons/si";
import { CiShop } from "react-icons/ci";
import { MdOutlinePassword } from "react-icons/md";
import "./Sidebar.scss";

const Sidebar = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    let path = location.pathname.slice(1);
    let currentPath = path.split("/")[0];
    setActiveTab(currentPath);
  }, [location.pathname]);

  const setTab = (place) => {
    localStorage.setItem("tab", place);
    setActiveTab(place);
  };

  const items = [
    {
      name: "About Me",
      icon: <SiAboutdotme />,
      path: "about",
    },
    { name: "Orders", icon: <CiShop />, path: "orders" },
    {
      name: "All Categories",
      icon: <BiCategory />,
      path: "all-categories",
    },
    { name: "Hair Care", icon: <GrServices />, path: "hair-care" },
    { name: "Skin Care", icon: <GrServices />, path: "skin-care" },
    {
      name: "Nail, Lash, and Brow Care",
      icon: <GrServices />,
      path: "nail-lash-brow-care",
    },
    {
      name: "Therapeutic Courses",
      icon: <GrServices />,
      path: "therapeutic-courses",
    },
    { name: "Offers", icon: <BiSolidOffer />, path: "offers" },
    {
      name: "Change Password",
      icon: <MdOutlinePassword />,
      path: "change-password",
    },
  ];

  return (
    <>
      <div className="sidebar">
        <div className="sticky">
          <div className="sidebar-logo">
            <img src={"/logo.png"} alt="Logo" className="logo-image" />
          </div>
          <ul className="sidebar-list">
            {items.map((item, index) => (
              <Link
                to={item.path}
                onClick={() => setTab(item.path)}
                key={index}
              >
                <li
                  className={`sidebar-item ${
                    activeTab === item.path ? "visited" : ""
                  }`}
                >
                  {item.icon}
                  {item.name}
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
