import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../constant";
import { ArchiveOrderAPI, GetOrdersAPI } from "../Api";

export const GetOrdersAction = createAsyncThunk("admin/orders/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetOrdersAPI + info.page
            + "&size=" + info.size + "&isPaginate=" + info.isPaginate
            + "&status=" + info.status);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const ArchiveOrderAction = createAsyncThunk("admin/orders/archive", async (id, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.put(ArchiveOrderAPI + id);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});