import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import "./ChangePassword.scss";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordAction } from "../../redux/actions/SettingAction";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.setting);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChangeOldPassword = (e) => {
    setOldPassword(e.target.value);
  };

  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(oldPassword, newPassword, confirmPassword);
    let info = new FormData();
    info.append("password", oldPassword);
    info.append("new_password", newPassword);
    info.append("confirm_password", confirmPassword);
    dispatch(ChangePasswordAction(info))
      .then(({ payload }) => {
        console.log(payload);
        if (payload?.status) {
          toast.success(payload.message);
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          toast.error(payload.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="change-password cs-container">
      <h4 className="title">Change Password</h4>
      <form className="content " onSubmit={submitHandler}>
        <div className="input-wrapper">
          <span>Old Password</span>
          <InputText
            value={oldPassword}
            required
            className="custom-input"
            type="password"
            placeholder="Old Password"
            onChange={handleChangeOldPassword}
          />
        </div>
        <div className="input-wrapper">
          <span>New Password</span>
          <InputText
            value={newPassword}
            required
            className="custom-input"
            type="password"
            placeholder="New Password"
            onChange={handleChangeNewPassword}
          />
        </div>
        <div className="input-wrapper">
          <span>Confirm Password</span>
          <InputText
            value={confirmPassword}
            required
            className="custom-input"
            type="password"
            placeholder="Confirm Password"
            onChange={handleChangeConfirmPassword}
          />
        </div>
        <div className="action">
          <Button
            size="small"
            className="pink"
            type="submit"
            label="Submit"
            loading={loading}
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
